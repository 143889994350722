import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import Container from "../components/container";
import { Link } from "gatsby";
import { FaCommentDollar, FaCloud, FaFile } from "react-icons/fa";
import * as styles from "./index.module.css";

const Metodyfinansowania = ({}) => {
  return (
    <FunctionPageLayout
      title="Financing methods"
      headline="for implementation projects"
    >
      <SEO
        title="Methods of financing IT software for business"
        description="Financing methods ✅ System purchase • System rental • System leasing • Financing methods for IT projects"
        keywords={["Financing methods"]}
      />
      <p>
        <strong>
          Are you wondering how you can finance the implementation of IT
          software?
        </strong>
      </p>
      <p>
        We propose financing of implementation projects in several different
        ways, and each of them has its own individual advantages. It is worth
        familiarizing yourself with the pros of each solution to choose the one
        that best suits the needs of your organization.
      </p>
      <br />
      <h3>Available financing models:</h3>
      <BenefitBlockWrapper>
        <BenefitBlock icon={<FaCloud size="60px" />} title="Rent" />
        <BenefitBlock icon={<FaCommentDollar size="60px" />} title="Purchase" />
        <BenefitBlock icon={<FaFile size="60px" />} title="Lease" />
      </BenefitBlockWrapper>
      <br />
      <h3>
        <strong>What is business software rental?</strong>
      </h3>
      <p>
        {" "}
        Renting is a solution that means that maintaining the system on the
        server is our responsibility. Your cost is only a fixed amount specified
        in the contract, and all other issues are on the side of the software
        provider.
      </p>
      <p>
        <strong>Why is it worth it?</strong>{" "}
      </p>
      <p>
        <ul>
          <li>
            <strong>No maintenance costs</strong> — maintaining your system on
            the provider's secure servers.{" "}
          </li>
          <li>
            <strong>Help and support</strong> — access to technical support and
            how-to knowledge, as well as free standard support from the
            supplier.
          </li>
          <li>
            <strong>Updates</strong> — constant free access to software updates
            containing new functionalities and improvements.
          </li>

          <li>
            <strong>Fast implementation</strong> — SaaS model ensures quick
            implementation.
          </li>
          <li>
            <strong>Safety</strong> — it is the provider who deals with the
            security of the system and your data.
          </li>
        </ul>
      </p>
      <br />
      <h3>
        <strong>What is business software purchase? </strong>{" "}
      </h3>
      <p>
        {" "}
        In this model you get the system license and then store it on your
        servers. The purchase of IT software is associated with its independent
        maintenance and operation.
      </p>
      <p>
        <strong>Why is it worth it?</strong>{" "}
      </p>
      <p>
        <ul>
          <li>
            <strong> Individual license</strong> — you get a lifetime system
            license.{" "}
          </li>
          <li>
            <strong>Updates</strong> — you have annual access to many updates
            containing new functionalities and improvements.
          </li>
          <li>
            <strong>Full control over the data</strong> — you decide yourself
            how you protect your data.
          </li>
        </ul>
      </p>
      <br />
      <h3>
        <strong> What is business software leasing? </strong>
      </h3>
      <p>
        {" "}
        IT leasing applies only to one-time purchase of the software. Under the
        lease agreement, it is possible to finance the purchase of the system
        and the implementation service. You commit to incur monthly leasing
        installments over a set period.
        <strong>
          This financing method allows you to spread the costs of an
          implementation project over a 5-year period.
        </strong>
      </p>
      <p>
        <strong>Why is it worth it?</strong>{" "}
      </p>
      <p>
        <ul>
          <li>
            <strong>It allows for the financing of the entire project</strong> —
            purchase of both the license and implementation services.{" "}
          </li>
          <li>
            <strong>Minimal formalities</strong> — the number of required
            documents has been reduced to a minimum.
          </li>
          <li>
            <strong>We cooperate with an expert</strong> in the field of IT
            leasing - Grenke Leasing - individual service and substantive
            support at every stage.
          </li>
          <li>
            <strong>Cost reduction</strong> — leasing installments are fully
            included in tax deductible costs, which reduces the tax base.
          </li>
          <li>
            <strong>Protection of the company's capital</strong> — IT leasing
            does not burden the company's financial balance sheet and thus does
            not worsen its current condition.
          </li>
        </ul>
      </p>
      <br />
      <br />

      <h2> Do you want to talk about your project? </h2>

      <Container>
        <div
          style={{
            textAlign: "center",
            margin: "20px",
            padding: "10px",
          }}
        >
          <Link className={styles.startNow} to="/kontakt/">
            Get in touch with us
          </Link>
        </div>
      </Container>
    </FunctionPageLayout>
  );
};

export default Metodyfinansowania;
